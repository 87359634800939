<template>
  <div id="app">
    <div class="header" v-if="$route.path !== '/login' && $route.path !== '/' && $route.path !== '/register'">
      <div class="logo">
        <img src="@/assets/index/logo.png" alt="">
      </div>
      <div class="navbar">
        <ul>
          <li><router-link  :to="{ path: '/'}">HOME</router-link></li>
          <li><router-link  :to="{ path: '/accessories'}">ACCESSORIES</router-link></li>
          <li><router-link  :to="{ path: '/aboutUs'}">ABOUT US</router-link></li>
          <li><router-link  :to="{ path: '/contactUs'}">CONTACT US</router-link></li>
        </ul>
      </div>
      <div class="right">
        <div class="cart" @click="toShopCard">
          <el-badge :value="cartItemCount" class="item">
            <i class="el-icon-goods"></i>
          </el-badge>
        </div>
        <el-popover
            placement="top-start"
            width="150"
            trigger="hover"
            :visible-arrow="false"
        >
          <div class="user-info" style="padding: 0 20px">
            <div class="user-action" v-if="hasToken" @click="handleLogout">Logout</div>
            <div class="user-action" v-else @click="toLogin">Login/Register</div>
            <div class="user-action" @click="toOrderHistory">Order History</div>
          </div>
          <i class="el-icon-user" slot="reference" style="cursor: pointer"></i>
        </el-popover>
      </div>
    </div>
<!--    <div class="bottom" v-if="$route.path !== '/login' && $route.path !== '/'  && $route.path !== '/register'"></div>-->
    <router-view />
    <ShopCard ref="shopCard"></ShopCard>
  </div>

</template>

<script>


import { cartList } from "@/api/cart";
import { logout } from "@/api/login";

export default {
  name: 'App',
  data(){
    return {
      cartItemCount: 0,
    }
  },
  computed: {
    hasToken() {
      return !!this.$store.getters.token
    }
  },
  watch: {
    // 监听路由变化
    '$route'(to, from) {
      if (to.path !== '/login' && to.path !== '/register') {
        this.fetch() // 每次路由变化时请求数据
      }
    }
  },
  methods:{
    async fetch() {
      this.loading = true
      try {
        const res = await cartList()
        const {code, data} = res
        if (code === 0) {
          this.list = data
          this.cartItemCount = this.list.length;
          console.log(this.cartItemCount, 62)
        } else if (code === 401) {
          this.toLogin();
        }
      } catch (error) {
        console.error('Fetch error:', error);
      } finally {
        this.loading = false;
      }
    },
    toLogin() {
      this.$router.push({ path: '/login' })
    },
    async handleLogout() {
      await this.$store.dispatch('user/logout')
      await this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    toOrderHistory(){
      this.$router.push({
        path: '/orderHistory'
      })
    },
    toShopCard(){
      console.log('shopCard-----------', this.$refs.shopCard)
      this.$refs.shopCard.handleOpen()
    },
  }
}
</script>

<style scoped lang="scss">
#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  min-width: 1440px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid #DADADA;
  background: #fff;
  box-sizing: border-box;

  .navbar{
    ul{
      display: flex;
      justify-content: space-between;
      list-style: none;
      li{
        margin-right: 64px;
      }
      a{
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 25px;
        text-align: left;
        font-style: normal;
        text-transform: none
      }
    }
  }
  .right{
    font-size: 18px;
    .cart{
      position: relative;
      display: inline-block;
      margin-right: 36px;
      cursor: pointer;
    }
  }
}
.el-popover, .el-popper {
  .user-info {
    .user-action {
      height: 40px;
      line-height: 40px;
      cursor: pointer
    }
  }
}
</style>
