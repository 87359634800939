<template>
  <div class="about-container">
    <div class="container">
      <div class="title">About us</div>
      <div class="content">
        <div class="content-left">
          <div class="content-inner">
            <div class="inner-title">
              <div class="icon"></div>
              <span>Company Overview</span>
            </div>
            <div class="inner-content">
              ZXL is a renowned international electronics accessories retail company with a history spanning over 3 years.
              During this time, we have established a strong presence in the global market and have become a trusted name among consumers.
            </div>
          </div>
          <div class="content-inner">
            <div class="inner-title">
              <div class="icon"></div>
              <span>Product Range</span>
            </div>
            <div class="inner-content">
              Our extensive product catalog is one of our key strengths. It includes a diverse selection of smartphone accessories.
              For instance, we offer a wide variety of smartphone cases crafted from different materials like hard plastic, silicone,
              and leather, available in an array of colors and designs to suit every taste and provide both protection and style.
            </div>
          </div>
          <div class="content-inner">
            <div class="inner-title">
              <div class="icon"></div>
              <span>Quality Assurance</span>
            </div>
            <div class="inner-content">
              Quality is the cornerstone of our business. We have forged partnerships with reliable manufacturers and suppliers who
              follow strict quality control procedures. Our in-house team of experts conducts rigorous inspections and testing on
              each product. They assess factors such as build quality, durability, and functionality to ensure that only products
              that meet our high standards reach our customers.
            </div>
          </div>
        </div>
        <div class="content-right">
          <img src="@/assets/aboutUs/aboutUs.png" alt="">
        </div>

      </div>
    </div>
    <Contact></Contact>
  </div>
</template>

<script>
import Contact from "@/components/contact.vue";

export default {
  name: 'ContactUs' ,
  components: {Contact}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.about-container{
  .container{
    width: 1440px;
    margin: 0 auto;
    .title{
      font-weight: 600;
      font-size: 30px;
      color: #151515;
      text-align: left;
      padding-bottom: 32px;
      border-bottom: 1px solid #DADADA;
      margin-bottom: 35px;
      margin-top: 150px;
    }
    .content{
      text-align: left;
      display: flex;
      justify-content: space-between;
      padding-bottom: 100px;
      .content-left{
        width: 880px;
        .content-inner{
          margin-bottom: 48px;
          .inner-title{
            display: flex;
            font-weight: 600;
            font-size: 18px;
            color: #151515;
            align-items: center;
            margin-bottom: 20px;
            .icon{
              width: 0;
              height: 0;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-bottom: 10px solid #FF6912;
              transform: rotate(90deg);
              margin-right: 3px;
            }
          }
          .inner-content{
            font-weight: 400;
            font-size: 16px;
            color: #666666;
            line-height: 26px;
            margin-left: 16px;
          }
        }
      }
      .content-right{
        & > img {
          width: 419px;
          height: 465px;
        }
      }
    }
  }
}
</style>
