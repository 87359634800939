import { Message } from 'element-ui';
import { login, logout } from '@/api/login'
import {getCurrent} from "@/api/user";
import {getToken, setToken, getUsername, removeToken} from '@/utils/auth'
const state = {
    token: getToken() || '',
    username: getUsername() || ''
}
const mutations = {
    setToken(state, newToken) {
        state.token = newToken
        setToken(newToken)
    },
    SET_USER_ID: (state, userId) => {
        state.userId = userId
    },
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo
    }
}

const actions = {
    async login(context, data) {
        const res = await login(data)
        if ( res.code === 0 ) {
            context.commit('setToken', res.data.token)
        } else {
            Message.error('Login failure')
        }
    },
    async logout() {
        await logout()
        removeToken()
    },
    async getUserInfo(context,) {
        const {code, data} = await getCurrent()
        console.log(data, 31)
        if(code === 0) {
            context.commit('SET_USER_ID', data.id)
            context.commit('SET_USER_INFO', data)
        }
    },
    clear({ commit }) {
        commit('SET_USER_ID', null)
        commit('SET_USER_INFO', {})
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}
