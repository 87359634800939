<template>
  <div class="accessories-container">
    <div class="search" v-if="isSearch">
      <div class="search-container">
        <el-input
            placeholder="Search for accessories"
            prefix-icon="el-icon-search"
            v-model="filters.name"
            @change="handleInput"
        >
        </el-input>
      </div>
      <div class="search-result">
        <div class="search-result-left">Found 11 results</div>
        <div class="search-result-right">
          <div class="title">brand: </div>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{currentBrandName}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
              <el-dropdown-item v-for="item in brandList" :key="item.id" :command="item">{{item.name}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <ProductIndex ref="productList" :category-list="categoryList" :loading-category="loadingCategory" @update-resultNum="updateResultNum"></ProductIndex>
    <Contact></Contact>
  </div>
</template>

<script>
import Contact from '@/components/contact.vue'
import ProductIndex from "@/components/product/product.vue";
import {getBrandList, getCategoryList} from "@/api/device";
export default {
  name: 'ACCESSORIES',
  components: { ProductIndex, Contact},
  data(){
    return {
      brandList: [],
      currentBrandIndex: 0,
      categoryList: [],
      loadingCategory: false,
      isSearch: false,
      currentBrandName: '',
      filters: {
        name: ''
      },
      resultNum: 0
    }
  },
  mounted() {
    this.isSearch = this.$route.query.isSearch;
    console.log(this.isSearch, 56)
    const {fetchBrandList} = this
    fetchBrandList()
  },
  methods:{// 获取品牌列表
    async fetchBrandList(){
      const {currentBrandIndex, fetchCategoryList} = this
      const res = await getBrandList()
      const {code, data} = res
      if(code === 0){
        this.brandList = data
        if(this.brandList.length > 0){
          const {id, name} = this.brandList[currentBrandIndex]
          this.currentBrandName = name
          fetchCategoryList(id)
        }
      }
    },
    // 获取类型列表
    async fetchCategoryList(brandId){
      this.loadingCategory = true
      const res = await getCategoryList(brandId)
      const {code, data} = res
      if(code === 0){
        this.categoryList = data
        console.log(this.categoryList, 102)
        this.loadingCategory = false
      }
    },
    // 点击品牌
    checkBrand(item,index){
      this.currentBrandIndex = index
      const {id} = item
      this.fetchCategoryList(id)
    },
    //点击下拉菜单
    handleCommand({id, name}){
      this.fetchCategoryList(id)
      console.log(name, 91)
      this.currentBrandName = name
    },
    handleInput(e){
      this.$refs.productList.handleSearch(e)
    },
    updateResultNum(num){
      this.resultNum = num
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.custom-dropdown-menu {
  max-height: 400px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::v-deep .el-dropdown-menu__item{
    font-weight: 400;
    font-size: 16px;
    color: #151515;
    line-height: 19px;
    text-align: left;
    font-style: normal;
    margin-bottom: 24px;
  }
}
.accessories-container{
  margin-top: 80px;
  .search{
    &-container{
      padding: 50px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #DADADA;
      ::v-deep .el-input{
        width: 900px;
        height: 60px;
        background: #F5F5F5;
        border-radius: 133px 133px 133px 133px;
        .el-input__inner{
          width: 100%;
          height: 100%;
          background: #F5F5F5;
          border-radius: 133px 133px 133px 133px;
          padding-left: 60px;
          font-size: 20px;

        }

      }
      ::v-deep .el-input__prefix{
        left: 30px;
      }
      ::v-deep .el-input__icon{
        font-size: 20px;
        margin-top: 3px;
      }
    }
    &-result{
      padding: 25px 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-left{
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 19px;
        text-align: left;
        font-style: normal;
      }
      &-right{
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        display: flex;
        align-items: center;
        .el-dropdown-link{
          font-weight: 400;
          font-size: 16px;
          color: #151515;
        }

      }
    }
  }
  .logo-container{
    padding: 32px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EDE6E4;
    overflow-x: scroll;
    white-space: nowrap;
    .device-logo{
      width: 221px;
      height: 68px;
      padding: 0 35px;
      background: #FFFFFF;
      border-radius: 11px 11px 11px 11px;
      border: 1px solid #E5E5E5;
      margin-right: 32px;
      display: flex;
      align-items: center;
      color: #888888;
      justify-content: center;
      img{
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
      .name{
        font-weight: 500;
        font-size: 27px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .active{
      border: 1px solid #333333;
      color: #151515;
    }
  }

}
</style>
