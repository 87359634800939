import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import { removeToken } from '@/utils/auth'
import router from '@/router'


// import router from '@/router'

const service = axios.create({
    // ! 配置请求头
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000
})

// request interceptor
service.interceptors.request.use(
    config => {
        if (store.getters.token) {
            // ! 配置token
            config.headers['Authorization'] = 'Bearer ' + store.getters.token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const { data } = response
        // eslint-disable-next-line no-console

        // if the custom code is not 20000, it is judged as an error.
        if (data.code !== 0) {
            Message({
                message: data.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })
        }
        return data
    },
    error => {
        // eslint-disable-next-line no-console
        console.log('err' + error) // for debug

        switch (error.response.status) {
            case 400:
                Message.error(error.response.data.message)
                break
            case 401:
                Message.error('Not logged in or logged in expired')
                removeToken()
                window.location.href = '/login?redirect=' + router.history.current.path
                break
            case 404:
                Message.error('The accessed resource does not exist')
                break
            case 405:
                Message.error('API request type error')
                break
            case 422:
                Message.error(error.response.data.message)
                break
            case 500:
                Message.error('System error')
                break
            default:
                Message({
                    message: error.message,
                    type: 'error',
                    duration: 5 * 1000
                })
                break
        }

        return Promise.reject(error)
    }
)

export default service
