import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5f5eabb8&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=5f5eabb8&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f5eabb8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\12762\\Desktop\\HMproject\\mam-computer-portal\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f5eabb8')) {
      api.createRecord('5f5eabb8', component.options)
    } else {
      api.reload('5f5eabb8', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=5f5eabb8&scoped=true", function () {
      api.rerender('5f5eabb8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/aboutUs/index.vue"
export default component.exports