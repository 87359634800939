var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contact" }, [
    _c("div", { staticClass: "contact-container" }, [
      _c("div", { staticClass: "contact-inner-container" }, [
        _c("div", { staticClass: "title" }, [_vm._v("About Us")]),
        _c(
          "div",
          {
            staticClass: "center",
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.toAboutUs },
          },
          [_vm._v("About Us")]
        ),
        _c("div", { staticClass: "foot" }, [_vm._v("Privacy Policy")]),
      ]),
      _c("div", { staticClass: "contact-inner-container" }, [
        _c("div", { staticClass: "title" }, [_vm._v("My Account")]),
        _c("div", { staticClass: "center" }, [_vm._v("My Account")]),
        _c(
          "div",
          {
            staticClass: "foot",
            staticStyle: { cursor: "pointer" },
            on: { click: _vm.toOrderHistory },
          },
          [_vm._v("Order History")]
        ),
      ]),
      _vm._m(0),
    ]),
    _c("div", { staticClass: "footer-container" }, [
      _vm._v(" Copyright © 2024 Shadowsocks.com. All Rights Reserved. "),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "contact-inner-container" }, [
      _c("div", { staticClass: "title" }, [_vm._v("Contact Us")]),
      _c("div", { staticClass: "pic-container" }, [
        _c("img", {
          attrs: { src: require("@/assets/index/f-word.png"), alt: "" },
        }),
        _c("img", {
          attrs: { src: require("@/assets/index/camera.png"), alt: "" },
        }),
        _c("img", {
          attrs: { src: require("@/assets/index/bird.png"), alt: "" },
        }),
        _c("img", {
          attrs: { src: require("@/assets/index/wechat.png"), alt: "" },
        }),
        _c("img", {
          attrs: { src: require("@/assets/index/phone.png"), alt: "" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }