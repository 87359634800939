var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-container" },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "title" }, [_vm._v("Contact us")]),
        _c("div", { staticClass: "content-container" }, [
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "baidu-map",
                {
                  staticClass: "map-container",
                  attrs: { center: { lng: 103.8198, lat: 1.3521 }, zoom: 12 },
                },
                [
                  _c(
                    "bm-marker",
                    { attrs: { position: { lng: 103.8198, lat: 1.3521 } } },
                    [
                      _c("bm-info-window", {
                        attrs: { content: "新加坡总部位置" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._m(0),
              _vm._m(1),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "right" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    rules: _vm.rules,
                    model: _vm.currentForm,
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "contact-form-row", attrs: { gutter: 24 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _vm._v("First name"),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "firstName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "First name",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.currentForm.firstName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentForm, "firstName", $$v)
                                  },
                                  expression: "currentForm.firstName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _vm._v("Last name"),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "lastName" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Last name",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.currentForm.lastName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentForm, "lastName", $$v)
                                  },
                                  expression: "currentForm.lastName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "contact-form-row", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _vm._v("Email"),
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "email" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "Email", type: "text" },
                                model: {
                                  value: _vm.currentForm.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentForm, "email", $$v)
                                  },
                                  expression: "currentForm.email",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _vm._v("Phone number"),
                          ]),
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Phone number",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.currentForm.phone,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentForm, "phone", $$v)
                                  },
                                  expression: "currentForm.phone",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "contact-form-row", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("div", { staticClass: "input-title" }, [
                            _vm._v("Message"),
                          ]),
                          _c(
                            "el-form-item",
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "Message",
                                  type: "textarea",
                                  autosize: { minRows: 4 },
                                },
                                model: {
                                  value: _vm.currentForm.message,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.currentForm, "message", $$v)
                                  },
                                  expression: "currentForm.message",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticClass: "contact-form-row", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "btn-container", attrs: { span: 24 } },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "submit-btn",
                              on: { click: _vm.save },
                            },
                            [_vm._v("Send message")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("Contact"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "phone" }, [
      _c("div", { staticClass: "tip" }, [_vm._v("Contact Number")]),
      _c("div", { staticClass: "number" }, [_vm._v("+628123456789")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "phone" }, [
      _c("div", { staticClass: "tip" }, [_vm._v("Social media")]),
      _c("div", { staticClass: "number pic-container" }, [
        _c("img", {
          attrs: { src: require("@/assets/index/f-word.png"), alt: "" },
        }),
        _c("img", {
          attrs: { src: require("@/assets/index/camera.png"), alt: "" },
        }),
        _c("img", {
          attrs: { src: require("@/assets/index/bird.png"), alt: "" },
        }),
        _c("img", {
          attrs: { src: require("@/assets/index/wechat.png"), alt: "" },
        }),
        _c("img", {
          attrs: { src: require("@/assets/index/phone.png"), alt: "" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }