var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _vm.$route.path !== "/login" &&
      _vm.$route.path !== "/" &&
      _vm.$route.path !== "/register"
        ? _c("div", { staticClass: "header" }, [
            _vm._m(0),
            _c("div", { staticClass: "navbar" }, [
              _c("ul", [
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: { path: "/" } } }, [
                      _vm._v("HOME"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "/accessories" } } },
                      [_vm._v("ACCESSORIES")]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: { path: "/aboutUs" } } }, [
                      _vm._v("ABOUT US"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "/contactUs" } } },
                      [_vm._v("CONTACT US")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "div",
                  { staticClass: "cart", on: { click: _vm.toShopCard } },
                  [
                    _c(
                      "el-badge",
                      {
                        staticClass: "item",
                        attrs: { value: _vm.cartItemCount },
                      },
                      [_c("i", { staticClass: "el-icon-goods" })]
                    ),
                  ],
                  1
                ),
                _c(
                  "el-popover",
                  {
                    attrs: {
                      placement: "top-start",
                      width: "150",
                      trigger: "hover",
                      "visible-arrow": false,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "user-info",
                        staticStyle: { padding: "0 20px" },
                      },
                      [
                        _vm.hasToken
                          ? _c(
                              "div",
                              {
                                staticClass: "user-action",
                                on: { click: _vm.handleLogout },
                              },
                              [_vm._v("Logout")]
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "user-action",
                                on: { click: _vm.toLogin },
                              },
                              [_vm._v("Login/Register")]
                            ),
                        _c(
                          "div",
                          {
                            staticClass: "user-action",
                            on: { click: _vm.toOrderHistory },
                          },
                          [_vm._v("Order History")]
                        ),
                      ]
                    ),
                    _c("i", {
                      staticClass: "el-icon-user",
                      staticStyle: { cursor: "pointer" },
                      attrs: { slot: "reference" },
                      slot: "reference",
                    }),
                  ]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("router-view"),
      _c("ShopCard", { ref: "shopCard" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: { src: require("@/assets/index/logo.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }