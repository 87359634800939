import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import store from './store'
Vue.config.productionTip = false
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'typeface-roboto';


import 'element-ui/lib/theme-chalk/index.css'
import ShopCard from './components/order/shopCard.vue'
import BaiduMap from 'vue-baidu-map'

Vue.use(BaiduMap, {
  ak: 'Ri3SKUWcM1o6vI95oaBHEfxkTMfm08pn'
})

Vue.component('ShopCard', ShopCard)
Vue.use(ElementUI, { locale })
Vue.use(VueRouter)
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
}).$mount('#app')
