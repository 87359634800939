<template>
  <div class="device-container">
    <div class="device-left" :class="{sticky: isSticky}">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="Product" name="1" >
          <div v-loading="loadingCategory">
            <div v-if="categoryList.length > 0">
              <div class="model-name" :class="{active: currentCategoryIndex === index}" v-for="(item, index) in categoryList" :key="item.id" @click="handleCheckCategory(item, index)">
                <div class="icon" v-if="currentCategoryIndex === index"></div>
                <div v-else style="width: 6px; height: 6px;"></div>
                {{ item.name }}
              </div>
            </div>
            <div v-else style="font-size: 12px;text-align: center">No products available at the moment</div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="Models" name="2">
          <div v-loading="loading.loadingModels">
            <div v-if="modelsList.length > 0">
              <div class="model-name" :class="{active: currentModelIndex === index}" v-for="(item, index) in modelsList" :key="item.id" @click="handleCheckModel(item, index)">
                <div class="icon" v-if="currentModelIndex === index"></div>
                <div v-else style="width: 6px; height: 6px;"></div>
                {{item.name}}
              </div>
            </div>
            <div v-else style="font-size: 12px;text-align: center">There are currently no models available</div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="device-right" v-loading="loading.loadingProduct">
      <div v-if="productList.length > 0">
        <div class="device-right-container">
          <div class="device-info" v-for="item in productList" :key="item.id">
            <div v-if="item.images && item.images.length > 0" class="image-container">
              <img :src="item.images[0].url" @click="toProductDetail(item.id, 'toOrder')" alt="">
            </div>
            <div v-else class="image-container">
              <img src="@/assets/product/product.png" @click="toProductDetail(item.id, 'toOrder')" alt="">
            </div>
            <div class="info">
              <div class="info-left">
                <div class="title">{{item.name}}</div>
                <div class="price">${{item.discountPrice.toFixed(2)}}</div>
              </div>
              <div class="info-right" style="cursor: pointer" @click="toProductDetail(item.id, 'toCart')">
                <img src="@/assets/index/gowuche.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="device-footer" v-if="showLoadMore" style="cursor:pointer;" @click="handleLoadMore">Load more</div>
      </div>
      <div v-else>
        <el-empty description="No Product"></el-empty>
      </div>
    </div>
    <ProductDetail ref="productDetail"></ProductDetail>
  </div>
</template>

<script>
import ProductDetail from "@/components/product/productDetail.vue";
import {getModelList} from "@/api/device";
import { getProductList} from "@/api/product";

export default {
  name: "productIndex",
  components: {ProductDetail},
  props:{
    categoryList:Array,
    loadingCategory: Boolean,
    isSticky: Boolean
  },
  data(){
    return{
      activeNames: ['1', '2'],
      modelsList: [],
      currentCategoryIndex: 0,
      currentModelIndex: 0,
      productList: [],
      sourceProductList: [],
      loading: {
       loadingModels: false,
        loadingProduct: false
      },
      displayedProductCount: 20,
      showLoadMore: false,
      filters:{
        name: '',
        modelId: ''
      }
    }
  },
  watch: {
    categoryList: {
      immediate: true, // 立即执行
      handler(newVal) {
        this.modelsList = []
        console.log('切换品牌-----', newVal)
        this.currentCategoryIndex = 0
        this.currentModelIndex = 0
        this.displayedProductCount = 20
        if (newVal.length > 0) {
          const {id} = newVal[this.currentCategoryIndex];
          this.fetchModelsList(id);
        } else {
          this.productList = []
          console.warn("categoryList is empty");
        }
      }
    }
  },
  methods:{
    // 获取型号列表
    async fetchModelsList(categoryId){
      this.loading.loadingModels = true
      const {currentModelIndex, fetchProductList} = this;
      const res = await getModelList(categoryId)
      const {code, data} = res
      if(code === 0){
        this.modelsList = data
        console.log(this.modelsList, 235)
        if(this.modelsList.length > 0){
          const {id} = this.modelsList[currentModelIndex]
          this.filters.modelId = id
          fetchProductList()
        }else{
          this.productList = []
        }
        this.loading.loadingModels = false
      }
    },
    // 获取产品列表
    async fetchProductList(){
      console.log(this.displayedProductCount, 118)
      this.loading.loadingProduct = true
      const res = await getProductList({...this.filters})
      const {code, data} = res
      if(code === 0){
        this.sourceProductList = data.map(item=>{
          return{
            ...item,
            images: JSON.parse(item.images).filter(i=>i.tag !== 'del')
          }
        })
        if(this.sourceProductList.length === this.displayedProductCount || this.sourceProductList.length < this.displayedProductCount){
          this.showLoadMore = false
          this.productList = this.sourceProductList
        }else{
          this.showLoadMore = true
          this.productList = this.sourceProductList.slice(0, this.displayedProductCount)
        }
        console.log(this.productList, 114)
        this.loading.loadingProduct = false
      }
    },
    // 切换类型
    async handleCheckCategory(item, index){
      this.currentCategoryIndex = index
      this.currentModelIndex = 0
      this.displayedProductCount = 20
      const {id} = item
      const {fetchModelsList} = this
      await fetchModelsList(id)
    },
    // 切换型号
    handleCheckModel(item, index){
      this.currentModelIndex = index
      this.displayedProductCount = 20
      const {id} = item
      this.filters.modelId = id
      const {fetchProductList} = this
      fetchProductList()
    },
    // 加载更多
    handleLoadMore(){
      this.displayedProductCount += 20
      this.productList = this.sourceProductList.slice(0, this.displayedProductCount)
      if(this.sourceProductList.length === this.displayedProductCount || this.sourceProductList.length < this.displayedProductCount) {
        this.showLoadMore = false
      }
    },
    handleChange(){
    },
    // 搜索
    handleSearch(name){
      console.log(name, 171)
      this.filters.name = name
      this.fetchProductList()
    },
    toProductDetail(id, type) {
      this.$refs.productDetail.handleOpen(id, type)
    }
  }
}
</script>

<style scoped lang="scss">
.device-container{
  display: flex;
  border: 1px solid #DADADA;
  background: #F8F4F3;
  .device-left{
    width: 243px;
    padding-left:23px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    ::v-deep .el-collapse {
      border-top: none;
      border-bottom: none;
    }
    ::v-deep .el-collapse-item__header{
      font-weight: 500;
      font-size: 18px;
      color: #151515;
      line-height: 21px;
      text-align: left;
      font-style: normal;
    }
    ::v-deep .el-collapse-item__content{
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 19px;
      text-align: left;
      font-style: normal;
      cursor:pointer;
    }
    .model-name{
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 24px;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
      position: relative;
      .icon{
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #151515;
        transform: rotate(-90deg);
        position: relative;
        left: 0px;
        margin-right: 3px;
        margin-left: -3px;
      }
    }

    //.model-name:hover{
    //  width: 324px;
    //  overflow: visible;
    //  white-space: wrap;
    //}
    .active{
      font-weight: 400;
      font-size: 16px;
      color: #151515;
    }
  }
  //.sticky {
  //  position: sticky;
  //  top: 255px;
  //  z-index: 10;
  //  background: #fff;
  //}

  .device-right{
    width: calc(100% - 243px);
    padding: 20px;
    position: relative;
    border-radius: 5px;
    .device-right-container {
      background: #F8F4F3;
      display: flex;
      flex-wrap: wrap;
      height: calc(100% - 160px);
      gap: 20px;
      .device-info {
        width: 274px;
        background: #FFFFFF;
        border-radius: 5px;
        box-sizing: border-box;
        .image-container {
          width: 274px;
          height: 250px;
          box-sizing: border-box;
          cursor: pointer;
          img{
            width: 274px;
            height: 250px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }
        }
        .info{
          height: 84px;
          display: flex;
          justify-content: space-between;
          align-items: end;
          //height: calc(100% - 250px);
          padding: 18px 20px;
          box-sizing: border-box;
          .info-left{
            font-weight: 400;
            font-size: 16px;
            color: #151515;
            line-height: 19px;
            text-align: left;
            font-style: normal;
            flex: 1;
            .price{
              color: #666666;
              margin-top: 10px;
            }
          }
          .info-right{
            width: 40px;
            height: 40px;
            background: #FFFFFF;
            border-radius: 50%;
            border: 1px solid #DBDBDB;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            img{
              width: 18px;
              height: 18px;
            }
          }
        }

      }
    }
    .device-footer{
      width: 145px;
      height: 47px;
      border-radius: 47px;
      font-weight: 400;
      font-size: 16px;
      color: #666666;
      line-height: 47px;
      text-align: center;
      font-style: normal;
      border: 1px solid #BEBEBE;
      margin: 50px auto;
    }
    ::v-deep .el-empty{
      padding: 80px 0;
    }
  }
}
</style>
