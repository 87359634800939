<template>
  <el-dialog :visible.sync="visible"  width="1140px" :before-close="handleClose">
    <div class="detail-container">
      <div class="top">
        <div class="left" v-if="productDetail.images && productDetail.images.length > 0">
          <div class="inner-left">
            <div class="img-container" :class="{active: currentBannerIndex === index}" v-for="(item, index) in productDetail.images" :key="item.id" @click="handleChangeBanner(item, index)">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="item.url"
                  fit="cover"
              >
              </el-image>
            </div>
          </div>
          <div class="inner-right">
            <el-image
                style="width: 100%; height: 100%"
                :src="bannerImageUrl"
                fit="cover"
            >
            </el-image>
          </div>
        </div>
        <div class="right">
          <div class="right-title">
            <div class="right-title-top">{{productDetail.name}}</div>
            <div class="right-title-center">
              <span v-if="productDetail.models && Object.keys(productDetail.models).length > 0">{{ Object.values(productDetail.models).join(', ') }}</span>
            </div>
            <div class="right-title-foot price">
              <span>price: </span>
              <span v-if="!checkedAttributesStock || Object.keys(checkedAttributesStock).length === 0">${{productDetail.price.toFixed(2)}}</span>
              <span v-else>{{checkedAttributesStock.price.toFixed(2)}}</span>
            </div>
            <div class="right-title-foot">
              <span class="price-title">discountPrice: </span>
              <span v-if="!checkedAttributesStock || Object.keys(checkedAttributesStock).length === 0">${{productDetail.discountPrice.toFixed(2)}}</span>
              <span v-else>{{checkedAttributesStock.discountPrice.toFixed(2)}}</span>
            </div>
          </div>
          <div v-if="productDetail.stocks.length > 0">
            <div class="right-attributes" v-for="(item, index) in productDetail.attributes" :key="item.id">
              <div class="attributes-title">{{ item.name }}:</div>
              <el-tag type="info" :class="{tagActive: item.currentTagIndex === valueIndex}"  effect="plain" v-for="(value, valueIndex) in item.values" :key="value.id" @click="checkedAttributeTag(item,value, index, valueIndex)">{{value.name}}</el-tag>
            </div>
          </div>
          <div class="btn-container">
            <div class="right-num">
              <div class="right-num-title">Purchase quantity:</div>
              <div class="right-num-number"><el-input-number v-model="selectedQuantity" :min="1" @change="handleChange"></el-input-number></div>
            </div>
            <div class="right-btn">
              <el-button class="submit-btn" @click="save" v-if="type === 'toOrder'">Order Now</el-button>
              <el-button class="submit-btn" @click="save" v-if="type === 'toCart'">Add To Cart</el-button>
            </div>
          </div>

        </div>
      </div>
      <div class="foot" v-if="productDetail.desc">
        <div class="foot-title">
          DESCRIPTION
          <div class="icon"></div>
        </div>
        <div class="foot-text" v-html="productDetail.desc"></div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {getProductDetail} from '@/api/product/index'
import {orderFromProduct} from "@/api/order";
import { edit} from "@/api/cart";
import {getAccessoryDetail} from "@/api/assessories";
import _ from "lodash";
export default {
  name: "productDetail",
  data(){
    return{
      visible: false,
      productDetail: {},
      bannerImageUrl: '',
      currentBannerIndex: 0,
      combinationAttribute: [],
      checkedAttributesStock: {},
      selectedQuantity: 1,
      type: '',
      productType: ''
    }
  },
  methods:{
    // 获取产品详情
    async fetchProductDetail(id){
      let action
      if(this.productType === 'accessory'){
        action = getAccessoryDetail
      }else{
        action = getProductDetail
      }
      const res = await action(id)
      const {code, data} = res
      if(code === 0){
        const parsedAttributes = data.attributes && JSON.parse(data.attributes).map(attr => ({
          ...attr,
          currentTagIndex: null
        }))

        this.productDetail = {
          ...data,
          images: JSON.parse(data.images).filter(item=>item.tag !== 'del'),
          attributes: parsedAttributes,
          stocks: data.stocks && data.stocks.length > 0 && data.stocks.map(item=>{
            return {
              ...item,
              sku: JSON.parse(item.sku),
            }
          })
        }
        console.log(this.productDetail.images, 116)
        if(this.productDetail.images && this.productDetail.images.length > 0){
          this.bannerImageUrl = this.productDetail.images[0].url
        }
        console.log(this.productDetail, 'ProductDetail----------')
        console.log(this.checkedAttributesStock, 112)
      }
    },
    handleChangeBanner(item, index){
      console.log(item, 77)
      this.currentBannerIndex = index
      this.bannerImageUrl = item.url
    },
    checkedAttributeTag(item,value, attributeIndex, valueIndex){
      console.log(item, '----------')
      console.log(value, '----------')
      console.log(attributeIndex, valueIndex, 111)
      this.selectedQuantity = 1
      // 如果点击的标签已经被选中，则取消选择
      if (this.productDetail.attributes[attributeIndex].currentTagIndex === valueIndex) {
        this.productDetail.attributes[attributeIndex].currentTagIndex = null;

        this.combinationAttribute = this.combinationAttribute.filter(
            attr => attr.attributeIndex !== attributeIndex
        );
        this.bannerImageUrl = this.productDetail.images[0].url;

        this.checkedAttributesStock = {};
        return;
      }
      this.productDetail.attributes[attributeIndex].currentTagIndex = valueIndex
      this.combinationAttribute = this.combinationAttribute || []
      // 检查是否已存在相同属性索引的组合
      const existingIndex = this.combinationAttribute.findIndex(
          attr => attr.attributeIndex === attributeIndex
      )
      if (existingIndex !== -1) {
        this.combinationAttribute[existingIndex] = {
          attributeIndex,
          valueId: value.id
        }
      } else {
        this.combinationAttribute.push({
          attributeIndex,
          valueId: value.id
        })
      }
      console.log('组合属性:', this.combinationAttribute)
      const checkedAttributesSku = this.combinationAttribute.map(item=>item.valueId)
      console.log(checkedAttributesSku, 139)
      const {stocks} = this.productDetail
      if(stocks){
        stocks.forEach(item=>{
          // 判断选择的属性和产品详情的属性
          if(_.isEqual(_.sortBy(item.sku), _.sortBy(checkedAttributesSku))){
            console.log(item, '144--------')
            this.checkedAttributesStock = item
            const { thumbnail } = item
            this.bannerImageUrl = thumbnail
          }
        })
      }
    },
    handleChange(e){
      if (e < 1) {
        console.log('小于1')
        this.selectedQuantity = 1;
      } else {
        console.log('输入的小数')
        this.selectedQuantity = Math.floor(e);
      }
      console.log(this.selectedQuantity, 182)
    },
    async save(){
      console.log(this.checkedAttributesStock, 171)
      if (this.productDetail.stocks && this.productDetail.stocks.length > 0) {
        if (!this.checkedAttributesStock || Object.keys(this.checkedAttributesStock).length === 0) {
          this.$message.warning('Please select product specifications!')
          return
        }
      }
      const data = {
        productId: this.productDetail.id,
        quantity: this.selectedQuantity,
        productStockId: this.checkedAttributesStock.id
      }
      let action
      let path
      if(this.type === 'toOrder'){
        action = orderFromProduct
        path = '/orderHistory'
      }else{
        action = edit
      }
      // if(this.type === 'toOrder'){
      //   this.$confirm('Are you sure to place the order now?', {
      //     confirmButtonText: 'yes',
      //     cancelButtonText: 'no',
      //     type: 'warning'
      //   })
      //   .then(async() => {
      //
      //   })
      //   .catch(() => {
      //   })
      // }
      const res = await action({...data})
      const {code} = res
      if (code === 0) {
        this.$message.success('Added successfully!')
        this.visible = false
        await this.$router.push({
          path,
        })
      }
    },
    handleOpen(id, type, productType){
      console.log(id, 73)
      this.type = type
      this.productType = productType
      this.visible = true
      this.fetchProductDetail(id)
    },
    handleClose(){
      this.visible = false
    }
  }
}
</script>


<style scoped lang="scss">
::v-deep .el-dialog__body{
  padding: 0;
}
::v-deep .el-dialog{
  border-radius: 18px;
}
.detail-container{
  padding: 60px;
  .top{
    display: flex;
    .left{
      display: flex;
      justify-content: space-between;
      margin-right: 60px;
      align-items: center;
      .inner-left{
        display: flex;
        flex-direction: column;
        .img-container{
          width: 72px;
          height: 72px;
          border: 1px solid #E3E3E3;
          margin-bottom: 16px;
          margin-right: 20px;
          img{
            width: 100%;
          }
        }
        .active{
          border: 2px solid #ff7829;
        }
      }
      .inner-right{
        width: 400px;
        height: 400px;
        background: #bbc5cb;
      }
    }
    .right{
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-title{
        margin-bottom: 50px;
        &-top{
          font-weight: bold;
          font-size: 24px;
          color: #151515;
          line-height: 28px;
          text-align: left;
          font-style: normal;
          margin-bottom: 12px;
        }
        &-center{
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          margin-bottom: 22px;
        }
        &-foot{
          font-weight: 500;
          font-size: 20px;
          color: #FF6912;
          line-height: 23px;
          text-align: left;
          font-style: normal;
        }
        .price{
          font-size: 20px;
          line-height: 23px;
          text-align: left;
          font-style: normal;
          font-weight: 400;
          color: #666666;
          text-decoration: line-through;
        }
        .price-title{
          font-weight: 400;
          font-size: 20px;
          color: #666666;
          line-height: 23px;
          text-align: left;
          font-style: normal;
        }
      }
      &-attributes{
        margin-bottom: 30px;
        .attributes-title{
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          margin-bottom: 10px;
        }
        ::v-deep .el-tag{
          margin-right: 10px;
        }
        .tagActive{
          border: 1px solid #151515;
          color: #151515;
        }
      }
      .btn-container{
        display: flex;
        align-items: end;
        .right-num{
          &-title{
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            line-height: 16px;
            text-align: left;
            font-style: normal;
            margin-bottom: 13px;
          }
        }
        .right-btn{
          margin-left: 30px;
          .submit-btn{
            width: 230px;
            height: 50px;
            background: #151515;
            border-radius: 8px;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
          }
        }
      }

    }
  }
  .foot{
    margin-top: 70px;
    &-title{
      font-weight: bold;
      font-size: 21px;
      color: #151515;
      line-height: 25px;
      text-align: left;
      border-bottom: 1px solid #DCDCDC;
      padding-bottom: 15px;
      position: relative;
      .icon{
        width: 77px;
        height: 4px;
        background: #151515;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    &-text{
      font-weight: 400;
      font-size: 19px;
      color: #666666;
      line-height: 30px;
      text-align: left;
      padding-top: 28px;
      font-style: normal;
    }
  }
}
</style>
