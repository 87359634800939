var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: "1140px",
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "detail-container" }, [
        _c("div", { staticClass: "top" }, [
          _vm.productDetail.images && _vm.productDetail.images.length > 0
            ? _c("div", { staticClass: "left" }, [
                _c(
                  "div",
                  { staticClass: "inner-left" },
                  _vm._l(_vm.productDetail.images, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "img-container",
                        class: { active: _vm.currentBannerIndex === index },
                        on: {
                          click: function ($event) {
                            return _vm.handleChangeBanner(item, index)
                          },
                        },
                      },
                      [
                        _c("el-image", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: { src: item.url, fit: "cover" },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "inner-right" },
                  [
                    _c("el-image", {
                      staticStyle: { width: "100%", height: "100%" },
                      attrs: { src: _vm.bannerImageUrl, fit: "cover" },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "right-title" }, [
              _c("div", { staticClass: "right-title-top" }, [
                _vm._v(_vm._s(_vm.productDetail.name)),
              ]),
              _c("div", { staticClass: "right-title-center" }, [
                _vm.productDetail.models &&
                Object.keys(_vm.productDetail.models).length > 0
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          Object.values(_vm.productDetail.models).join(", ")
                        )
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "right-title-foot price" }, [
                _c("span", [_vm._v("price: ")]),
                !_vm.checkedAttributesStock ||
                Object.keys(_vm.checkedAttributesStock).length === 0
                  ? _c("span", [
                      _vm._v("$" + _vm._s(_vm.productDetail.price.toFixed(2))),
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(_vm.checkedAttributesStock.price.toFixed(2))
                      ),
                    ]),
              ]),
              _c("div", { staticClass: "right-title-foot" }, [
                _c("span", { staticClass: "price-title" }, [
                  _vm._v("discountPrice: "),
                ]),
                !_vm.checkedAttributesStock ||
                Object.keys(_vm.checkedAttributesStock).length === 0
                  ? _c("span", [
                      _vm._v(
                        "$" + _vm._s(_vm.productDetail.discountPrice.toFixed(2))
                      ),
                    ])
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.checkedAttributesStock.discountPrice.toFixed(2)
                        )
                      ),
                    ]),
              ]),
            ]),
            _vm.productDetail.stocks.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.productDetail.attributes, function (item, index) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "right-attributes" },
                      [
                        _c("div", { staticClass: "attributes-title" }, [
                          _vm._v(_vm._s(item.name) + ":"),
                        ]),
                        _vm._l(item.values, function (value, valueIndex) {
                          return _c(
                            "el-tag",
                            {
                              key: value.id,
                              class: {
                                tagActive: item.currentTagIndex === valueIndex,
                              },
                              attrs: { type: "info", effect: "plain" },
                              on: {
                                click: function ($event) {
                                  return _vm.checkedAttributeTag(
                                    item,
                                    value,
                                    index,
                                    valueIndex
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(value.name))]
                          )
                        }),
                      ],
                      2
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c("div", { staticClass: "btn-container" }, [
              _c("div", { staticClass: "right-num" }, [
                _c("div", { staticClass: "right-num-title" }, [
                  _vm._v("Purchase quantity:"),
                ]),
                _c(
                  "div",
                  { staticClass: "right-num-number" },
                  [
                    _c("el-input-number", {
                      attrs: { min: 1 },
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.selectedQuantity,
                        callback: function ($$v) {
                          _vm.selectedQuantity = $$v
                        },
                        expression: "selectedQuantity",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "right-btn" },
                [
                  _vm.type === "toOrder"
                    ? _c(
                        "el-button",
                        { staticClass: "submit-btn", on: { click: _vm.save } },
                        [_vm._v("Order Now")]
                      )
                    : _vm._e(),
                  _vm.type === "toCart"
                    ? _c(
                        "el-button",
                        { staticClass: "submit-btn", on: { click: _vm.save } },
                        [_vm._v("Add To Cart")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm.productDetail.desc
          ? _c("div", { staticClass: "foot" }, [
              _c("div", { staticClass: "foot-title" }, [
                _vm._v(" DESCRIPTION "),
                _c("div", { staticClass: "icon" }),
              ]),
              _c("div", {
                staticClass: "foot-text",
                domProps: { innerHTML: _vm._s(_vm.productDetail.desc) },
              }),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }