var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "accessories-container" },
    [
      _c("div", { staticClass: "title-container sticky" }, [
        _c(
          "div",
          { staticClass: "title-left" },
          _vm._l(_vm.accessoryModelList, function (item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "title-left-container",
                on: {
                  click: function ($event) {
                    return _vm.handleChangeModel(item, index)
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "text",
                    class: { active: _vm.currentModelIndex === index },
                  },
                  [_vm._v(_vm._s(item.name))]
                ),
                _vm.currentModelIndex === index
                  ? _c("div", { staticClass: "description" })
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "title-right" },
          _vm._l(_vm.accessoryAttributeList, function (item, index) {
            return _c(
              "div",
              { key: item.id, staticClass: "attribute-container" },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.name) + ": "),
                ]),
                _c(
                  "el-dropdown",
                  { on: { command: (e) => _vm.handleCommand(index, e) } },
                  [
                    _c("span", { staticClass: "el-dropdown-link" }, [
                      _vm.checkedAttribute.length > 0
                        ? _c("span", [
                            _vm._v(_vm._s(_vm.checkedAttribute[index]?.name)),
                          ])
                        : _vm._e(),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ]),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass: "custom-dropdown-menu",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown",
                      },
                      _vm._l(item.children, function (i) {
                        return _c(
                          "el-dropdown-item",
                          { key: i.id, attrs: { command: i } },
                          [_vm._v(_vm._s(i.name))]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "list-container",
        },
        [
          _vm.accessoryList.length > 0
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "device-right-container" },
                  _vm._l(_vm.accessoryList, function (item) {
                    return _c(
                      "div",
                      { key: item.id, staticClass: "device-info" },
                      [
                        item.thumbnail
                          ? _c(
                              "div",
                              {
                                staticClass: "image-container",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toAccessoryDetail(
                                      item.id,
                                      "toOrder"
                                    )
                                  },
                                },
                              },
                              [
                                _c("el-image", {
                                  attrs: { src: item.thumbnail },
                                }),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              {
                                staticClass: "example-image",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toAccessoryDetail(
                                      item.id,
                                      "toOrder"
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/product/product.png"),
                                    alt: "",
                                  },
                                }),
                              ]
                            ),
                        _c("div", { staticClass: "info" }, [
                          _c("div", { staticClass: "info-left" }, [
                            _c("div", { staticClass: "title" }, [
                              _vm._v(_vm._s(item.name)),
                            ]),
                            _c("div", { staticClass: "price" }, [
                              _vm._v(
                                "$" + _vm._s(item.discountPrice.toFixed(2))
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "info-right",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.toAccessoryDetail(
                                    item.id,
                                    "toCart"
                                  )
                                },
                              },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/index/gowuche.png"),
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm.showLoadMore
                  ? _c(
                      "div",
                      {
                        staticClass: "device-footer",
                        staticStyle: { cursor: "pointer" },
                        on: { click: _vm.handleLoadMore },
                      },
                      [_vm._v("Load more")]
                    )
                  : _vm._e(),
              ])
            : _c(
                "div",
                [_c("el-empty", { attrs: { description: "No Accessory" } })],
                1
              ),
        ]
      ),
      _c("Contact"),
      _c("ProductDetail", { ref: "productDetail" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }