import request from '@/utils/request'

export function cartList(params) {
    return request({
        url: '/cart/list',
        method: 'get',
        params
    })
}

// 编辑购物车
export function edit(data) {
    return request({
        url: '/cart/upsert',
        method: 'post',
        data
    })
}

// 删除购物车
export function remove(id) {
    return request({
        url: `/cart/${id}`,
        method: 'delete'
    })
}

// 购物车创建订单
export function cartToOrder(data) {
    return request({
        url: `/order/cart`,
        method: 'post',
        data
    })
}

