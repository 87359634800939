<template>
  <div class="details-container">
    <div class="container">
      <div class="title">
        <div class="title-left">Order details</div>
        <div class="title-right">
          <a href="/orderHistory">Back to list</a>
        </div>
      </div>
      <div class="table-title">
        <div class="Number">
          <div class="inner-title">Order Number:</div>
          <div class="inner-content">{{detailData.orderNo}}</div>
        </div>
        <div class="Number">
          <div class="inner-title">Order Time:</div>
          <div class="inner-content">{{detailData.createTime}}</div>
        </div>
        <div class="Number">
          <div class="inner-title">Order status:</div>
          <div class="inner-content" v-if="detailData.status === 0" style="color: #FF6912">unconfirmed</div>
          <div class="inner-content" v-if="detailData.status === 1">Confirmed</div>
        </div>
      </div>
      <div class="detail" v-if="orderVoLists.length > 0">
        <div class="detail-container" v-for="(item, index) in orderVoLists" :key="index">
          <div class="detail-left">
            <el-image
                style="width: 100px; height: 100px;margin-right: 40px"
                :src="item.thumbnail"
                fit="cover"
            >
            </el-image>
            <div class="right">
              <div class="name">{{item.productName}}</div>
              <div class="price">{{item.productStockSkuValue}}</div>
            </div>
          </div>
          <div class="detail-right">
            <div class="price">${{item.price.toFixed(2)}}</div>
            <div class="quantity">x{{item.quantity}}</div>
            <div class="amount">${{item.totalPrice.toFixed(2)}}</div>
          </div>
        </div>

      </div>
      <div v-else class="empty">
        <el-empty description="No product information available at the moment"></el-empty>
      </div>
      <div class="subtotal">
        <div class="sub-title">Subtotal</div>
        <div class="price">${{detailData.totalAmount.toFixed(2)}}</div>
      </div>
    </div>
    <ContactUs></ContactUs>
  </div>
</template>

<script>
import ContactUs from "@/components/contact.vue";
import {getOrderDetail} from "@/api/order";

export default {
  name: "orderHistory",
  components: {ContactUs},
  data() {
    return {
      detailData: {},
      orderVoLists: []
    }
  },
  mounted() {
    const id = this.$route.query.orderId;
    console.log(id, 100)
    this.fetchDetail(id)
  },
  methods:{
  //   获取详情
    async fetchDetail(id){
      const res = await getOrderDetail(id)
      const {code, data} = res
      if(code === 0){
        const {orderVoList} = data
        this.detailData = data
        if(orderVoList && orderVoList.length > 0){
          this.orderVoLists = orderVoList.map(item=>{
            return {
              ...item,
              productStockSkuValue:item.productStockSkuValue && JSON.parse(item.productStockSkuValue).join('/')
            }
          })
        }
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.details-container {
  .container {
    max-width: 1440px; /* 设置版心的最大宽度 */
    margin-left: auto;
    margin-right: auto;
    padding: 154px 0 80px;
    //padding: 154px 240px 80px;
    .title {
      font-weight: 600;
      font-size: 30px;
      color: #151515;
      line-height: 35px;
      text-align: left;
      font-style: normal;
      margin-bottom: 35px;
      display: flex;
      justify-content: space-between;
      &-right{
        a{
          font-weight: 400;
          font-size: 14px;
          color: #1C7FF1;
          line-height: 16px;
          text-align: right;
          font-style: normal;
        }
      }
    }
    .table-title{
      padding: 45px 0;
      border-bottom: 1px solid #DADADA;
      border-top: 1px solid #DADADA;
      display: flex;
      .Number{
        font-weight: 400;
        font-size: 16px;
        color: #454545;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        margin-right: 150px;
        .inner-title{
          font-weight: 400;
          font-size: 14px;
          color: #888888;
          line-height: 16px;
          text-align: left;
          margin-bottom: 8px;
        }
      }
    }

    .detail::-webkit-scrollbar {
      display: none;
    }
    .detail{
      padding: 34px 0;
      max-height: 500px;
      overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 155px;
        .price{
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          line-height: 19px;
          text-align: left;
          font-style: normal;
        }
        .detail-left{
          display: flex;
          align-items: center;
          img{
            width: 80px;
            height: 80px;
            margin-right: 30px;
          }
          .right{
            text-align: left;
            .name{
              font-weight: 500;
              font-size: 16px;
              color: #151515;
              line-height: 19px;
              text-align: left;
              font-style: normal;
              margin-bottom: 12px;
            }
          }
        }
        .detail-right{
          width: 500px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: left;
          .price {
          }
          .quantity {

          }
          .amount {

          }
        }
      }
    }
    .subtotal{
      display: flex;
      justify-content: right;
      align-items: center;
      padding: 50px 0 125px 0;
      border-top: 1px solid #DADADA;
      .sub-title{
        font-weight: 400;
        font-size: 16px;
        color: #151515;
        line-height: 19px;
        text-align: left;
        font-style: normal;
        margin-right: 250px;
      }
      .price{
        font-weight: 500;
        font-size: 18px;
        color: #151515;
        line-height: 21px;
        text-align: left;
        font-style: normal;
      }
    }
  }
}
</style>
