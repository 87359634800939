import request from '@/utils/request'

// 品牌列表
export function getBrandList(params) {
    return request({
        url: '/device/brand',
        method: 'get',
        params
    })
}

// 类型列表
export function getCategoryList(brandId) {
    return request({
        url: `/device/category?brandId=${brandId}`,
        method: 'get'
    })
}

// 型号列表
export function getModelList(categoryId) {
    return request({
        url: `/device/model?categoryId=${categoryId}`,
        method: 'get'
    })
}
