var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "index-container" },
    [
      _c(
        "div",
        { staticClass: "header", class: { white: _vm.isHeaderWhite } },
        [
          _c("div", { staticClass: "header-container" }, [
            _vm._m(0),
            _c("div", { staticClass: "navbar" }, [
              _c("ul", [
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: { path: "/" } } }, [
                      _vm._v("HOME"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "/accessories" } } },
                      [_vm._v("ACCESSORIES")]
                    ),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: { path: "/aboutUs" } } }, [
                      _vm._v("ABOUT US"),
                    ]),
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { path: "/contactUs" } } },
                      [_vm._v("CONTACT US")]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("div", { staticClass: "right" }, [
              _c(
                "div",
                { staticClass: "cart", on: { click: _vm.toShopCard } },
                [
                  _c(
                    "el-badge",
                    {
                      staticClass: "item",
                      attrs: { value: _vm.cartItemCount },
                    },
                    [_c("i", { staticClass: "el-icon-goods" })]
                  ),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        placement: "top-start",
                        width: "150",
                        trigger: "hover",
                        "visible-arrow": false,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "user-info",
                          staticStyle: { padding: "0 20px" },
                        },
                        [
                          _vm.hasToken
                            ? _c(
                                "div",
                                {
                                  staticClass: "user-action",
                                  on: { click: _vm.handleLogout },
                                },
                                [_vm._v("Logout")]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "user-action",
                                  on: { click: _vm.toLogin },
                                },
                                [_vm._v("Login/Register")]
                              ),
                          _c(
                            "div",
                            {
                              staticClass: "user-action",
                              on: { click: _vm.toOrderHistory },
                            },
                            [_vm._v("Order History")]
                          ),
                        ]
                      ),
                      _c("i", {
                        staticClass: "el-icon-user",
                        staticStyle: { cursor: "pointer" },
                        attrs: { slot: "reference" },
                        slot: "reference",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c("div", { staticClass: "banner-container" }, [
        _vm._m(1),
        _c("div", { staticClass: "search", on: { click: _vm.toSearch } }, [
          _c("i", { staticClass: "el-icon-search" }),
          _c("span", { staticClass: "content" }, [
            _vm._v("Search for accessories"),
          ]),
        ]),
      ]),
      _c(
        "div",
        { ref: "logoContainer", staticClass: "logo-container" },
        _vm._l(_vm.brandList, function (item, index) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "device-logo",
              class: { active: _vm.currentBrandIndex === index },
              on: {
                click: function ($event) {
                  return _vm.checkBrand(item, index)
                },
              },
            },
            [item.image ? _c("img", { attrs: { src: item.image } }) : _vm._e()]
          )
        }),
        0
      ),
      _c("ProductIndex", {
        attrs: {
          "category-list": _vm.categoryList,
          "loading-category": _vm.loadingCategory,
          isSticky: _vm.isSticky,
        },
      }),
      _vm._m(2),
      _c("Contact"),
      _c("ShopCard", { ref: "shopCard" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "logo" }, [
      _c("img", {
        attrs: { src: require("@/assets/index/logo.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner" }, [
      _c("img", {
        attrs: { src: require("@/assets/index/banner.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "FAQs-container" }, [
      _c("div", { staticClass: "FAQ-title" }, [_vm._v("FAQs")]),
      _c("div", { staticClass: "FAQ-content" }, [
        _c("div", { staticClass: "content-left" }, [
          _vm._v(
            "How to proceed with logistics transportation after placing an order?"
          ),
        ]),
        _c("div", { staticClass: "content-right" }, [_vm._v("+")]),
      ]),
      _c("div", { staticClass: "FAQ-content" }, [
        _c("div", { staticClass: "content-left" }, [
          _vm._v("What if l can't find the accessories i'm looking for?"),
        ]),
        _c("div", { staticClass: "content-right" }, [_vm._v("+")]),
      ]),
      _c("div", { staticClass: "FAQ-content" }, [
        _c("div", { staticClass: "content-left" }, [
          _vm._v("What is the return policy?"),
        ]),
        _c("div", { staticClass: "content-right" }, [_vm._v("+")]),
      ]),
      _c("div", { staticClass: "FAQ-content" }, [
        _c("div", { staticClass: "content-left" }, [
          _vm._v("What are the customer service hours?"),
        ]),
        _c("div", { staticClass: "content-right" }, [_vm._v("+")]),
      ]),
      _c("div", { staticClass: "FAQ-content" }, [
        _c("div", { staticClass: "content-left" }, [
          _vm._v("Are all accessories guaranteed to be genuine?"),
        ]),
        _c("div", { staticClass: "content-right" }, [_vm._v("+")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }