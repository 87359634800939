<template>
  <div class="history-container">
    <div class="container">
      <div class="title">Order History</div>
<!--      <el-table style="width: 100%" :data="orderList" v-loading="loading">-->
<!--          <el-table-column label="details" prop="orderNo" align="center" width="550">-->
<!--            <template v-slot="{row:{orderNo, createTime, quantity, orderDetailVos}}">-->
<!--              <div class="head">-->
<!--                <div>Order Number: {{orderNo}}</div>-->
<!--                <div>Order Time: {{createTime}}</div>-->
<!--              </div>-->
<!--              <div style="display: flex;align-items: center;padding-left: 35px">-->
<!--                <div style="width: 80px;height: 80px;display: flex;align-items: center;margin-right: 10px" v-for="(item, index) in orderDetailVos.slice(0, 3)" :key="index">-->
<!--                  <img :src="item.productStockThumbnail || item.productThumbnail" alt="" style="width: 80px;height: 80px;">-->
<!--                </div>-->
<!--                <div>{{quantity}} pieces in total</div>-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="Subtotal" prop="" align="center">-->
<!--            <template v-slot="{row:{totalAmount}}">-->
<!--              <div class="head">-->
<!--              </div>-->
<!--              <div class="text">${{totalAmount.toFixed(2)}}</div>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="Order status" prop="status" align="center">-->
<!--            <template v-slot="{row:{status}}">-->
<!--              <div class="head">-->
<!--              </div>-->
<!--              <div style="height: 80px;line-height: 80px;padding-left: 35px;color: #FF560E;" v-if="status === 0">unConfirmed</div>-->
<!--              <div class="text" v-if="status === 1">Confirmed</div>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--          <el-table-column label="Operate" align="center" class-name="last-table">-->
<!--            <template v-slot="{row}">-->
<!--            <div class="head">-->
<!--            </div>-->
<!--              <div class="text">-->
<!--                <span class="operate" >view</span>-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--      </el-table>-->
      <div class="table-head">
        <div class="table-head-inner">details</div>
        <div class="subtotal">Subtotal</div>
        <div class="status">Order status</div>
        <div class="operate">Operate</div>
      </div>
      <div class="table-body" v-for="item in orderList" :key="item.id">
        <div class="body-head">
          <div class="order-no">
            <span class="tip">Order Number:</span>
            <span class="text">{{item.orderNo}}</span>
          </div>
          <div class="order-no">
            <span class="tip">Order Time:</span>
            <span class="text">{{item.createTime}}</span>
          </div>
        </div>
        <div class="body-content">
          <div class="table-head-inner">
            <div style="width: 60px;height: 60px;margin-right: 3px"  v-for="(i, index) in item.orderDetailVos.slice(0, 3)" :key="index">
              <img :src="i.thumbnail" alt="" style="width: 60px;height: 60px;">
            </div>
            <div class="quantity">{{item.quantity}} pieces in total</div>
          </div>
          <div class="subtotal">${{item.totalAmount}}</div>
          <div class="status" style="color: #FF560E;" v-if="item.status === 0">unconfirmed</div>
          <div class="status" v-if="item.status === 1">Confirmed</div>
          <div class="operate" style="text-decoration: underline;color: #1C7FF1;cursor:pointer;" @click="toOrderDetail(item)">view</div>
        </div>
      </div>

      <!--    分页-->
      <pagination
          v-show="pagination.total > 0"
          :auto-scroll="false"
          :total="pagination.total"
          :page.sync="pagination.current"
          :limit.sync="pagination.size"
          style="text-align: right"
          @pagination="fetch"
      />
    </div>
    <ContactUs></ContactUs>
    </div>
</template>

<script>
import ContactUs from "@/components/contact.vue";
import {getOrderList} from "@/api/order";
import Pagination from "@/components/Pagination/index.vue";

const defaultPagination = ()=>({
  current: 1,
  size: 5,
  total: 0
})
export default {
  name: "orderHistory",
  components: {ContactUs, Pagination},
  data() {
    return {
      orderList: [],
      pagination: defaultPagination(),
      loading: false
    }
  },
  mounted() {
    const {fetch} = this;
    fetch()
  },
  methods:{
  //   获取订单列表
    async fetch(){
      this.loading = true
      const res = await getOrderList({...this.pagination})
      const {code, data} = res
      if(code === 0){
        const {current, size, total, records} = data
        this.orderList = records.map(item=>{
          return {
            ...item,
            orderDetailVos:item.orderDetailVos.map(detail=>{
              return {
                ...detail
              }
            })
          }

        })
        this.pagination = {
          current,
          size,
          total
        }
        this.loading = false
        console.log(this.orderList, 83)
      }
    },
    toOrderDetail(row){
      console.log(row, 96)
      this.$router.push({
        path: '/orderDetail',
        query: {orderId: row.id}
      })
    }
  }

}
</script>


<style lang="scss" scoped>
.history-container {
  .container {
    max-width: 1440px; /* 设置版心的最大宽度 */
    margin-left: auto;
    margin-right: auto;
    padding: 154px 0 80px;

    .title {
      font-weight: 600;
      font-size: 30px;
      color: #151515;
      line-height: 35px;
      text-align: left;
      font-style: normal;
      margin-bottom: 35px;
    }
    .table-head{
      height: 40px;
      background: #FAFAFA;
      border: 1px solid #E3E3E3;
      font-weight: 400;
      font-size: 12px;
      color: #666666;
      line-height: 14px;
      text-align: left;
      font-style: normal;
      display: flex;
      align-items: center;
      margin-bottom: 14px;
      .table-head-inner{
        width: 550px;
        padding-left: 143px;
        box-sizing: border-box;
      }
      .subtotal{
        width: 333px;
        padding-left: 132px;
        box-sizing: border-box;
      }
      .status{
        width: 333px;
        padding-left: 108px;
        box-sizing: border-box;
      }
      .operate{
        width: 223px;
        padding-left: 93px;
        box-sizing: border-box;
      }
    }
    .table-body{
      height: 168px;
      border: 1px solid #E3E3E3;
      margin-bottom: 18px;
      .body-head{
        height: 46px;
        background: #FAFAFA;
        display: flex;
        align-items: center;
        padding-left: 28px;
        .order-no{
          font-weight: 400;
          font-size: 14px;
          color: #888888;
          line-height: 16px;
          text-align: left;
          font-style: normal;
          margin-right: 44px;
          .text{
            color: #151515;
          }
        }
      }
      .body-content{
        display: flex;
        align-items: center;
        padding: 30px;
        .table-head-inner{
          width: 550px;
          box-sizing: border-box;
          border-right:1px solid #F0F0F0;
          display: flex;
          align-items: center;
          .quantity{
            margin-left: 17px;
            font-weight: 400;
            font-size: 14px;
            color: #151515;
            text-align: left;
            font-style: normal;
          }
        }
        .subtotal{
          width: 333px;
          height: 60px;
          line-height: 60px;
          //padding-left: 113px;
          box-sizing: border-box;
          border-right:1px solid #F0F0F0;
        }
        .status{
          width: 333px;
          height: 60px;
          line-height: 60px;
          //padding-left: 119px;
          box-sizing: border-box;
          border-right:1px solid #F0F0F0;
        }
        .operate{
          width: 223px;
          height: 60px;
          line-height: 60px;
          padding-left: 91px;
          box-sizing: border-box;
        }
      }
    }

    ::v-deep .el-table__header-wrapper{
      margin-bottom: 20px;
    }
    ::v-deep .el-table__cell{
      background: #FAFAFA;
      border: 1px solid #E3E3E3;
      border-right: none;
      border-left: none;
    }
    ::v-deep .el-table__body-wrapper{
      ::v-deep .el-table__cell{
        background: #fff !important;
        border-right: none;
        border-left: none;
        ::v-deep .cell{
          background: #fff !important;
        }
      }
    }

    ::v-deep .el-table__cell:first-child {
      border-left: 1px solid #E3E3E3;
    }
    ::v-deep .last-table {
      border-right: 1px solid #E3E3E3;
    }
    //.operate{
    //  font-weight: 400;
    //  font-size: 19px;
    //  color: #1C7FF1;
    //  line-height: 22px;
    //  text-align: left;
    //  font-style: normal;
    //  text-decoration-line: underline;
    //  cursor: pointer;
    //}
    .head{
      background: #FAFAFA;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      padding-left: 5px;
    }
    .text{
      height: 80px;
      line-height: 80px;
      padding-left: 5px;
    }
  }
}
</style>
