var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "details-container" },
    [
      _c("div", { staticClass: "container" }, [
        _vm._m(0),
        _c("div", { staticClass: "table-title" }, [
          _c("div", { staticClass: "Number" }, [
            _c("div", { staticClass: "inner-title" }, [
              _vm._v("Order Number:"),
            ]),
            _c("div", { staticClass: "inner-content" }, [
              _vm._v(_vm._s(_vm.detailData.orderNo)),
            ]),
          ]),
          _c("div", { staticClass: "Number" }, [
            _c("div", { staticClass: "inner-title" }, [_vm._v("Order Time:")]),
            _c("div", { staticClass: "inner-content" }, [
              _vm._v(_vm._s(_vm.detailData.createTime)),
            ]),
          ]),
          _c("div", { staticClass: "Number" }, [
            _c("div", { staticClass: "inner-title" }, [
              _vm._v("Order status:"),
            ]),
            _vm.detailData.status === 0
              ? _c(
                  "div",
                  {
                    staticClass: "inner-content",
                    staticStyle: { color: "#FF6912" },
                  },
                  [_vm._v("unconfirmed")]
                )
              : _vm._e(),
            _vm.detailData.status === 1
              ? _c("div", { staticClass: "inner-content" }, [
                  _vm._v("Confirmed"),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm.orderVoLists.length > 0
          ? _c(
              "div",
              { staticClass: "detail" },
              _vm._l(_vm.orderVoLists, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "detail-container" },
                  [
                    _c(
                      "div",
                      { staticClass: "detail-left" },
                      [
                        _c("el-image", {
                          staticStyle: {
                            width: "100px",
                            height: "100px",
                            "margin-right": "40px",
                          },
                          attrs: { src: item.thumbnail, fit: "cover" },
                        }),
                        _c("div", { staticClass: "right" }, [
                          _c("div", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.productName)),
                          ]),
                          _c("div", { staticClass: "price" }, [
                            _vm._v(_vm._s(item.productStockSkuValue)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "detail-right" }, [
                      _c("div", { staticClass: "price" }, [
                        _vm._v("$" + _vm._s(item.price.toFixed(2))),
                      ]),
                      _c("div", { staticClass: "quantity" }, [
                        _vm._v("x" + _vm._s(item.quantity)),
                      ]),
                      _c("div", { staticClass: "amount" }, [
                        _vm._v("$" + _vm._s(item.totalPrice.toFixed(2))),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "empty" },
              [
                _c("el-empty", {
                  attrs: {
                    description:
                      "No product information available at the moment",
                  },
                }),
              ],
              1
            ),
        _c("div", { staticClass: "subtotal" }, [
          _c("div", { staticClass: "sub-title" }, [_vm._v("Subtotal")]),
          _c("div", { staticClass: "price" }, [
            _vm._v("$" + _vm._s(_vm.detailData.totalAmount.toFixed(2))),
          ]),
        ]),
      ]),
      _c("ContactUs"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "title-left" }, [_vm._v("Order details")]),
      _c("div", { staticClass: "title-right" }, [
        _c("a", { attrs: { href: "/orderHistory" } }, [_vm._v("Back to list")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }