import request from '@/utils/request'

// 产品列表
export function getProductList(params) {
    return request({
        url:  `/device/product`,
        method: 'get',
        params
    })
}

// 产品详情
export function getProductDetail(productId) {
    return request({
        url:  `/device/productDetail?productId=${productId}`,
        method: 'get'
    })
}


